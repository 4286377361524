import CrossStorage from "@delanobgt/admin-core/apis/crossStorage";
import { LocalStorageKeys } from "@delanobgt/admin-core/util/contants";
import { goPromise } from "@delanobgt/admin-core/util/helpers";
import { useSnackbar } from "material-ui-snackbar-provider";
import React from "react";
import { useDispatch } from "react-redux";
import { actions } from "src/redux/auth/ducks";

function Logout() {
  const dispatch = useDispatch();
  const snackbar = useSnackbar();

  const logout = React.useCallback(async () => {
    dispatch(actions.signOut());
    await goPromise(
      CrossStorage.getInstance().del(LocalStorageKeys.AUTH_TOKEN)
    );
    snackbar.showMessage("Logged Out successfully.");
  }, [dispatch, snackbar]);

  React.useEffect(() => {
    logout();
  }, [logout]);

  return <div />;
}

export default Logout;
