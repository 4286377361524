import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/redux";

import AdminRoute from "./routes/LoggedInRoutes";
import LoginRoute from "./routes/LoginRoutes";

export default () => {
  const token = useSelector<RootState, string>((state) => state.auth.token);
  if (token) return <AdminRoute />;
  return <LoginRoute />;
};
