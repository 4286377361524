import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Launch as LaunchIcon } from "@material-ui/icons";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { IAuthUser } from "src/core/auth/entities";
import { RootState } from "src/redux";

const useStyles = makeStyles((theme) => ({
  halfBackground: {
    width: "100%",
    height: "50vh",
    backgroundColor: theme.palette.primary.main,
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
    padding: 0,
    margin: 0,
  },
  homeIconWrapper: { display: "flex", alignItems: "center" },
  text: {
    color: "white",
  },
  emp: {},
}));

function Dashboard() {
  const classes = useStyles({});

  const currentUser = useSelector<RootState, IAuthUser>(
    (state) => state.auth.user
  );

  const visitMarketplace = React.useCallback((target: string) => {
    window.open(process.env.REACT_APP_WARDITE_URL, target).focus();
  }, []);

  const visitOfficialStore = React.useCallback((target: string) => {
    window.open(process.env.REACT_APP_AMIDIN_URL, target).focus();
  }, []);

  return (
    <>
      <div className={classes.halfBackground} />

      <br />
      <Typography variant="h6" className={classes.text}>
        Welcome back,{" "}
        <span className={classes.emp}>{currentUser.username}</span>
      </Typography>
      <Typography variant="subtitle1" className={classes.text}>
        {moment().format("Do MMMM YYYY")}
      </Typography>
      <br />
      <br />
      <br />
      <Grid container spacing={3}>
        {Boolean(process.env.REACT_APP_WARDITE_URL) && (
          <Grid item sm={4}>
            <Card>
              <CardActionArea onClick={() => visitMarketplace("_self")}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Marketplace
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Click to visit Marketplace Admin Panel.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions style={{ justifyContent: "flex-end" }}>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => visitOfficialStore("_blank")}
                >
                  <LaunchIcon />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        )}

        {Boolean(process.env.REACT_APP_AMIDIN_URL) && (
          <Grid item sm={4}>
            <Card>
              <CardActionArea onClick={() => visitOfficialStore("_self")}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Official Store
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Click to visit Official Store Admin Panel.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions style={{ justifyContent: "flex-end" }}>
                <IconButton
                  size="small"
                  onClick={() => visitOfficialStore("_blank")}
                >
                  <LaunchIcon />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default Dashboard;
