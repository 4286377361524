import { IRoute } from "@delanobgt/admin-core/util/types";
import Login from "src/pages/Auth/Login";

export type TLoginRouteNames = "LOGIN";

export const loginRoutes: Record<TLoginRouteNames, IRoute> = {
  LOGIN: {
    Icon: null,
    path: "/login",
    Page: Login,
  },
};
