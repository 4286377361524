import { ApiClientFactory } from "@delanobgt/admin-core/util/types";
import celestineClientFactory from "src/apis/celestine";

import IAuthRepository from "./auth/repository";
import AuthRepository from "./auth/repository/repository";
import IAuthUsecase from "./auth/usecase";
import AuthUsecase from "./auth/usecase/usecase";

interface IRepositories {
  authRepository: IAuthRepository;
}

function initRepositories(
  celestineClientFactory: ApiClientFactory
): IRepositories {
  return {
    authRepository: new AuthRepository(celestineClientFactory),
  };
}

interface IUsecases {
  auth: IAuthUsecase;
}

function initUsecases(repos: IRepositories): IUsecases {
  return {
    auth: new AuthUsecase(repos.authRepository),
  };
}

const repos = initRepositories(celestineClientFactory);
const Interactors: IUsecases = initUsecases(repos);

export default Interactors;
