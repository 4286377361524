import { goPromise } from "@delanobgt/admin-core/util/helpers";
import { ApiClientFactory } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import { IAuthUser } from "../entities";
import IAuthRepository from "../repository";

export default class AuthRepository implements IAuthRepository {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private AUTH_ROUTE = "/admin/auth";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Can't get data!");
  }

  async signIn(credentials: {
    username: string;
    password: string;
  }): Promise<{ token: string; user: IAuthUser }> {
    const [err, response] = await goPromise(
      this.apiClientFactory().post(`${this.AUTH_ROUTE}/signin`, credentials)
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }

  async getMe(): Promise<IAuthUser> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.AUTH_ROUTE}/me`)
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data.user;
  }

  async refreshToken(): Promise<string> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.AUTH_ROUTE}/refresh`)
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data.token;
  }
}
