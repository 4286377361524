import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { reducer as formReducer } from "redux-form";
import { combineEpics, createEpicMiddleware } from "redux-observable";

import authReducer from "./auth/ducks";

export const rootReducer = combineReducers({
  auth: authReducer,
  form: formReducer,
});

export const rootEpic = combineEpics();

export function configureStore() {
  const epicMiddleware = createEpicMiddleware();
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(epicMiddleware))
  );
  epicMiddleware.run(rootEpic);
  return store;
}

export type RootState = ReturnType<typeof rootReducer>;
