import React from "react";

import { Box, makeStyles } from "@material-ui/core";

interface IProps {
  children: React.ReactChild;
}

const useStyles = makeStyles(() => ({
  box: {
    backgroundColor: "white",
    borderRadius: 12,
    overflow: "hidden",
  },
}));

export default (props: IProps) => {
  const { children } = props;

  const classes = useStyles({});

  return (
    <Box boxShadow={3} className={classes.box}>
      {children}
    </Box>
  );
};
