import CrossStorage from "@delanobgt/admin-core/apis/crossStorage";
import FormError from "@delanobgt/admin-core/components/error/FormError";
import { TextField } from "@delanobgt/admin-core/redux-form/fields";
import { requiredValidator } from "@delanobgt/admin-core/redux-form/validators";
import { LocalStorageKeys } from "@delanobgt/admin-core/util/contants";
import { goPromise } from "@delanobgt/admin-core/util/helpers";
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  AccountCircle as AccountCircleIcon,
  VpnKey as VpnKeyIcon,
} from "@material-ui/icons";
import { useSnackbar } from "material-ui-snackbar-provider";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import Interactors from "src/core/Interactors";
import { RootState } from "src/redux";
import { actions } from "src/redux/auth/ducks";

import Backdrop from "./login/Backdrop";
import LoginPaper from "./login/LoginPaper";

interface IFormProps {
  username: string;
  password: string;
}

interface IComponentProps {}

const useStyles = makeStyles(() => ({
  loginImage: {
    backgroundSize: "cover",
    backgroundPosition: "center center",
  },
  depatuMark: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const _Form = (
  props: IComponentProps & InjectedFormProps<IFormProps, IComponentProps>
) => {
  const { handleSubmit } = props;

  const snackbar = useSnackbar();
  const classes = useStyles({});
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const dispatch = useDispatch();

  const defectBirth = useSelector<RootState, boolean>(
    (state) => state.auth.defectBirth
  );

  const onSubmit = async (credentials: {
    username: string;
    password: string;
  }) => {
    setLoading(true);
    const [err, res] = await goPromise(Interactors.auth.signIn(credentials));
    setLoading(false);
    if (err) {
      console.log(err);
      setError(err);
    } else {
      await goPromise(
        CrossStorage.getInstance().set(LocalStorageKeys.AUTH_TOKEN, res.token)
      );
      dispatch(actions.signIn(res));
      snackbar.showMessage("Logged In successfully.");
    }
  };

  return (
    <>
      <Backdrop active={!defectBirth} />

      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ width: "100vw", height: "100vh" }}
      >
        <Grid item xs={11} sm={12} md={6} lg={4}>
          <LoginPaper>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ padding: "2.5rem" }}
            >
              <div className={classes.depatuMark}>
                <img
                  alt=""
                  src={require("src/assets/images/icons/depatu.png")}
                  style={{ width: "2.5rem" }}
                />
              </div>
              <Typography variant="subtitle1" align="center">
                DEPATU
              </Typography>
              <br />
              <Typography
                variant="h6"
                align="center"
                style={{ color: "#ef5350" }}
              >
                <em>Admin Gate</em>
              </Typography>
              <br />
              <Field
                name="username"
                type="text"
                component={TextField}
                label="Username"
                validate={[requiredValidator]}
                disabled={loading || defectBirth}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Field
                name="password"
                type="password"
                component={TextField}
                label="Password"
                validate={[requiredValidator]}
                disabled={loading || defectBirth}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <VpnKeyIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <FormError error={error} />
              {defectBirth && (
                <Typography color="error" variant="body2">
                  Connection to SSO Server failed. Please refresh page.
                </Typography>
              )}
              <br />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading || defectBirth}
                fullWidth
              >
                {loading ? <CircularProgress size={24} /> : "LOGIN"}
              </Button>
            </form>
          </LoginPaper>
        </Grid>
      </Grid>
    </>
  );
};

const Form = reduxForm<IFormProps, IComponentProps>({
  form: "loginForm",
})(_Form);

export default function Login() {
  return <Form />;
}
