import Link from "@delanobgt/admin-core/components/link/BasicLink";
import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AccountCircle as AccountCircleIcon } from "@material-ui/icons";
import React from "react";
import { useSelector } from "react-redux";
import JwtTimer from "src/components/misc/JwtTimer";
import { IAuthUser } from "src/core/auth/entities";
import { RootState } from "src/redux";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  appBar: {
    zIndex: 5,
  },
}));

export default function Nav() {
  const classes = useStyles({});
  const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
  const isProfileMenuOpen = Boolean(profileAnchorEl);
  const currentUser = useSelector<RootState, IAuthUser>(
    (state) => state.auth.user
  );

  const handleProfileMenuOpen = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setProfileAnchorEl(event.currentTarget);
    },
    [setProfileAnchorEl]
  );

  const handleProfileMenuClose = React.useCallback(() => {
    setProfileAnchorEl(null);
  }, [setProfileAnchorEl]);

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          {/* <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            <MenuIcon />
          </IconButton> */}
          <img
            alt=""
            src={require("src/assets/images/icons/depatu.png")}
            style={{ height: 25, marginRight: "0.5rem" }}
          />
          <Typography
            className={classes.title}
            variant="h6"
            color="inherit"
            noWrap
          >
            Admin Gate
          </Typography>
          <div className={classes.grow} />
          <Typography variant="subtitle2" color="inherit">
            {currentUser.username}
          </Typography>
          {/* <div>&nbsp;|&nbsp;</div> */}
          <JwtTimer />
          <div>
            <IconButton
              color="inherit"
              edge="end"
              onClick={handleProfileMenuOpen}
            >
              <AccountCircleIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Menu
        anchorEl={profileAnchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isProfileMenuOpen}
        onClose={handleProfileMenuClose}
      >
        <MenuItem onClick={handleProfileMenuClose}>
          <Link to="/logout">Logout</Link>
        </MenuItem>
      </Menu>
    </div>
  );
}
