import { IRoute } from "@delanobgt/admin-core/util/types";
import {
  Dashboard as DashboardIcon,
  ExitToApp as ExitToAppIcon,
} from "@material-ui/icons";
import Logout from "src/pages/Auth/Logout/Logout";
import Dashboard from "src/pages/Dashboard/Dashboard";

export type TAdminRouteNames = "DASHBOARD" | "LOGOUT";

export const adminRoutes: Record<TAdminRouteNames, IRoute> = {
  DASHBOARD: {
    Icon: DashboardIcon,
    path: "/dashboard",
    Page: Dashboard,
  },
  LOGOUT: {
    Icon: ExitToAppIcon,
    path: "/logout",
    Page: Logout,
  },
};
