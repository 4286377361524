import "./backdrop/style.css";

import React from "react";
import P5Wrapper from "react-p5-wrapper";

import sketch from "./backdrop/sketch";

interface IProps {
  active: boolean;
}

export default class Backdrop extends React.Component<IProps, {}> {
  render() {
    return (
      <div className="login">
        {/* overlay */}
        <div
          style={{
            background: `rgba(194, 59, 34, 0.75)`,
            position: "absolute",
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            zIndex: -4,
          }}
        />

        {/* canvas */}
        {this.props.active && (
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100vw",
              height: "100vh",
              zIndex: -5,
            }}
          >
            <P5Wrapper sketch={sketch} />
          </div>
        )}
      </div>
    );
  }
}
