import BreadcrumbLink from "@delanobgt/admin-core/components/link/BreadcrumbLink";
import { Breadcrumbs as MUIBreadcrumbs, makeStyles } from "@material-ui/core";
import { Home as HomeIcon } from "@material-ui/icons";
import _ from "lodash";
import React from "react";
import { Breadcrumbs, BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Redirect, Route, Switch } from "react-router-dom";
import Nav from "src/pages/Nav";

import { adminRoutes } from "./loggedInRoutes/AdminRoutes";

const useStyles = makeStyles((theme) => ({
  homeIconWrapper: { display: "flex", alignItems: "center" },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginLeft: 0,
    zIndex: 5,
  },
}));

const routeWeight = (route: string): number => {
  const ROUTE_WEIGHTS: Record<string, number> = {
    [adminRoutes.DASHBOARD.path]: -100,
  };
  return ROUTE_WEIGHTS[route] || route.length;
};

export default () => {
  const classes = useStyles({});

  return (
    <>
      <Nav />

      <main className={classes.content}>
        {/* breadcrumbs */}
        <Breadcrumbs
          compare={(a: any, b: any) => routeWeight(a.to) - routeWeight(b.to)}
          renameProps={{
            children: "label",
          }}
          container={MUIBreadcrumbs}
          containerProps={{}}
          item={BreadcrumbLink}
          finalItem={BreadcrumbLink}
          finalProps={{
            style: { color: "black" },
            disabled: true,
          }}
        />
        <br />

        <BreadcrumbsItem to={adminRoutes.DASHBOARD.path}>
          <div className={classes.homeIconWrapper}>
            <HomeIcon />
          </div>
        </BreadcrumbsItem>

        <Switch>
          {_.values(adminRoutes)
            .filter((route) => Boolean(route.Page))
            .map((route) => {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  component={route.Page}
                />
              );
            })}

          <Route path="*">
            <Redirect to={adminRoutes.DASHBOARD.path} />
          </Route>
        </Switch>
      </main>
    </>
  );
};
